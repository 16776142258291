header {
    margin-top: 5rem;
    height: calc(100vh - 5rem);
    display: grid;
    place-items: center;
    background: var(--color-white);
    transition: var(--transition);
}

.header__title {
    flex-direction: column;
    gap: 1rem;
}

.header__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.header__profile {
    width: 18rem;
    height: 18rem;
    border-radius: 9rem 9rem 9rem 9rem;
    overflow: hidden;
    margin-top: 3rem;
    margin-bottom: 2rem;
}

.btn.sm.header__title {
    width:20rem;
    border: none;
    background: transparent;
}

.btn.sm.header__title:hover {
    transform: translateY(-0.5rem);
}

.header__profile{
    animation: header-title-spin infinite 20s linear;
}

@keyframes header-title-spin{
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.btn.skill_badge {
    width: 150px;
    height: 150px;
    background-color: transparent;
    border: none;
    display: inline-block;
    margin-top: 1.5rem;
    padding: none;
}

.btn.skill_badge:hover {
    transform: translateY(-0.5rem);
}

.header__container p {
    width: 35rem;
    text-align: center;
    margin: 0.6rem 0 2rem;
}

.header__cta {
    display: flex;
    align-items: center;
    gap: 1.2rem;
}

.header__socials {
    position: absolute;
    left: 0;
    bottom: 40%;
    display: flex;
    flex-direction:column;
    gap: 1rem;
}

.username {
    width: 50%;
    opacity: .65;
    font-size: medium;
    transform: rotate(-90deg);
}

.header__socials a {
    width: 1.8rem;
    aspect-ratio: 1/1;
    background: var(--color-light);
    color: var(--color-black);
    display: grid;
    place-items: center;
    border-radius: var(--border-radius-3);
}

.header__socials a:hover {
    background: var(--color-primary);
    color: var(--color-white);
    transform: rotate(-50deg);
}

/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 1024px) {
    header {
        margin-top: 0;
        height: 100vh;
    }
}


/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 600px) {
    .header__profile {
        margin-top: 7rem;
        margin-bottom: 1rem;
        width: 12rem;
        height: 12rem;
    }

    .header__container p {
        width: 100%;
    }

    .header__cta {
        gap: 1rem;
    }

    .header__socials {
        display: none;
    }

    .btn.skill_badge {
        margin: 0;
        width: 99px;
        height: 100px;
    }

    .header__title {
        width:17rem;
    }

    .btn.sm.header__title {
        width:12rem;
        border: none;
        background: transparent;
    }
}

@media screen and (max-width: 400px) {
    .header__profile {
        margin-top: 7rem;
        margin-bottom: 1rem;
    }

    .btn.skill_badge {
        margin: 0;
        width: 100px;
    }
}