#testimonials {
    padding-bottom: 0;
}

.swiper {
    height: fit-content;
    padding-bottom: 12rem;
    /* this paddint top is to give space for micro interaction on hover of swiper slide */
    padding-top: 1rem;
}

.swiper-slide {
    cursor: grab;
}

.testimonial__client-avatar {
    border-radius: var(--border-radius-2);
    overflow: hidden;
    transition: var(--transition);
}

.swiper-pagination {
    margin-bottom: 8rem;
}


.swiper-pagination-bullet {
    background: var(--color-primary);
}